<template>
  <section class="create-user">
    <div class="create-user__buttons" v-if="menus.length > 1">
      <router-link class="create-user__button-router" v-for="(menu, id) in menus" :key="id" :to="menu.to">{{ menu.tag }}</router-link>
    </div>
    <router-view></router-view>
  </section>
</template>
<script>
  import { mapGetters } from "vuex";

  export default {
    components: {},
    data() {
      return {
        menus: [],
      };
    },
    methods: {
      ...mapGetters("user", { getRole: "getRole" }),
    },
    mounted: function() {
      if (this.getRole() === "superadmin") {
        this.menus.push({
          to: { name: "location" },
          tag: "Sedes",
        });
      }
    },
  };
</script>

<style lang="scss">
  .create-user {
    width: 100%;
    margin: 0 auto;
    max-width: 1440px;
    .router-link-exact-active.router-link-active {
      border-bottom: 2px solid $primary;
      background-color: $white;
    }
    &__title {
      margin: 5px;
      text-align: center;
    }
    &__buttons {
      @include Row(space-around);
      width: 100%;
      margin: auto;
    }
    &__button-router {
      width: 100%;
      padding: 5px 0;
      text-align: center;
      text-decoration: none;
      color: initial;
      border: 1px solid $alto;
      &:hover {
        color: initial;
        font-weight: bold;
        text-decoration: none;
      }
    }
    @media screen and (min-width: $tablet_width) {
      &__title {
        font-size: $title-desktop;
      }
    }
  }
</style>
